import React, { MutableRefObject, useRef } from 'react';
import { Chevron } from '@solent-university/solent-icons';

import useBreakpoint from 'Common/hooks/useBreakpoint';
import useSliderScroll from 'Common/hooks/useSliderScroll';

import {
    Heading,
    Container,
    TableWrapper,
    Row,
    HeadingRow,
    Cell,
    HeadingCell,
    Slider,
    SliderControl,
    StyledIcon,
    StyledIconReverse
} from './Table.styled';

export interface Props {
    heading?: string;
    headingType?: 'h3' | 'h2';
    tableHeadings: string[];
    tableRows: (string | React.ReactNode)[][];
}

const Table: React.FC<Props> = props => {
    const { heading, tableHeadings, tableRows, headingType = 'h3' } = props;

    const tableRef = useRef() as MutableRefObject<HTMLDivElement>;

    const isMobile = !useBreakpoint(768);
    const scrollBreakpoint = isMobile ? 2 : 4;
    const showScroll = tableHeadings.length > scrollBreakpoint;

    const {
        handleScroll,
        handleScrollThumbMouseDown,
        handleTouchStart,
        sliderTrackWidth,
        sliderWidth,
        scrollPosition
    } = useSliderScroll(tableRef);

    return tableHeadings.length !== 0 && tableRows.length !== 0 ? (
        <Container $heading={heading}>
            {heading && <Heading as={headingType}>{heading}</Heading>}
            <TableWrapper
                role="table"
                tabIndex={showScroll ? 0 : -1}
                ref={tableRef}
                onScroll={handleScroll}
            >
                <div role="rowgroup">
                    <HeadingRow role="row">
                        {tableHeadings.map(heading => (
                            <HeadingCell key={heading} role="columnheader">
                                {heading}
                            </HeadingCell>
                        ))}
                    </HeadingRow>
                </div>
                {isMobile && showScroll && (
                    <Slider style={{ width: sliderTrackWidth }}>
                        <SliderControl
                            style={{
                                width: sliderWidth ? sliderWidth : undefined,
                                left: scrollPosition
                            }}
                            onMouseDown={handleScrollThumbMouseDown}
                            onTouchStart={handleTouchStart}
                        >
                            <StyledIconReverse as={Chevron} />
                            <span>Slide</span>
                            <StyledIcon as={Chevron} />
                        </SliderControl>
                    </Slider>
                )}
                <div role="rowgroup">
                    {tableRows.map((row, rowIndex) => (
                        <Row
                            key={`row-${rowIndex}`}
                            role="row"
                            $numberOfCols={tableHeadings.length}
                        >
                            {row.map((cell, cellIndex) => (
                                <Cell key={`row-${rowIndex}-cell-${cellIndex}`} role="cell">
                                    {cell}
                                </Cell>
                            ))}
                        </Row>
                    ))}
                </div>
            </TableWrapper>
        </Container>
    ) : null;
};

export default Table;
