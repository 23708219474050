import { Block, ComponentBlock } from '@contensis/canvas-react';
import HTMLReactParser from 'html-react-parser';

import cmsParser from 'Common/util/cmsParser';

import { H2 } from 'Elements/Typography';

import type { VideoProps } from 'Components/Video';
import type { LineListProps } from 'Components/List/LineList';
import type { AccordionProps } from 'Components/Accordion';

import CanvasDataMapper, { defaultGroupedBlockTypes, type MappedBlock } from './CanvasDataMapper';

export interface FiftyTwoWeeksCanvasProps {
    video?: {
        embed: VideoProps;
    };
    list?: LineListProps & {
        heading?: string;
    };
    accordionCollectionCanvas?: AccordionProps[];
}

const FiftyTwoWeeksArticlePage = (
    canvasContent: Block[]
): MappedBlock<FiftyTwoWeeksCanvasProps>[] => {
    return CanvasDataMapper<FiftyTwoWeeksCanvasProps>(canvasContent, ComponentMapper, undefined, [
        ...defaultGroupedBlockTypes,
        '_table'
    ]);
};

const ComponentMapper = (data: ComponentBlock): FiftyTwoWeeksCanvasProps => {
    switch (data.properties?.component) {
        case 'youTubeVideo':
            return {
                video: {
                    embed: cmsParser.video(data.value)
                }
            };
        case 'list':
            return {
                list: {
                    heading: data.value.label,
                    items: data.value.item.map((listItem, index) => ({
                        text: listItem,
                        id: index
                    }))
                }
            };
        case 'accordionCollectionCanvas':
            return {
                accordionCollectionCanvas: data.value.accordion.map((item, index) => ({
                    id: index,
                    heading: (
                        <H2
                            weight={'bold'}
                            size={{ base: '1.5rem', large: '1.75rem' }}
                            spacing={{ base: { margin: 0 } }}
                        >
                            {item.title}
                        </H2>
                    ),
                    button: {
                        ariaLabel: `View ${item.title}`
                    },
                    children: HTMLReactParser(item.content)
                }))
            };
        default:
            return {};
    }
};

export default FiftyTwoWeeksArticlePage;
