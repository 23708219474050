import { Block, ComponentBlock } from '@contensis/canvas-react';
import NextImage from 'next/image';

import cmsParser from 'Common/util/cmsParser';

import { useTheme } from 'styled-components';

import type { ImageProps } from 'Elements/Image';

import type { ImageCaptionProps } from 'Components/ImageCaption';
import type { VideoProps } from 'Components/Video';
import type { WaveQuoteBlockProps } from 'Components/QuoteBlock/Wave';
import { CarouselProps } from 'Components/Carousel';

import CanvasDataMapper, { type MappedBlock } from './CanvasDataMapper';

export interface GraduateStoryCanvasProps {
    image?: {
        imageCaption: ImageCaptionProps;
        columnWidth: number;
    };
    imageCanvas?: ImageProps;
    video?: {
        embed: VideoProps;
    };
    quoteBlock?: WaveQuoteBlockProps;
    carousel?: CarouselProps;
}

const GraduateStoryPage = (canvasContent: Block[]): MappedBlock<GraduateStoryCanvasProps>[] => {
    return CanvasDataMapper<GraduateStoryCanvasProps>(canvasContent, ComponentMapper);
};

const ComponentMapper = (data: ComponentBlock): GraduateStoryCanvasProps => {
    const { colors } = useTheme();

    switch (data.properties?.component) {
        case 'imageCaption':
            return {
                image: {
                    imageCaption: {
                        image: cmsParser.image(data.value.image, '/cms'),
                        caption: data.value.image.caption
                    },
                    columnWidth: parseInt(data.value.columnWidth)
                }
            };
        case 'imageCanvas':
            return {
                imageCanvas: cmsParser.image(data.value.image, '/cms')
            };
        case 'youTubeVideo':
            return {
                video: {
                    embed: cmsParser.video(data.value)
                }
            };
        case 'quoteWithHeadshot':
            return {
                quoteBlock: {
                    quote: data.value.quoteText,
                    image:
                        data.value.image && data.value.image.src !== '/cms'
                            ? cmsParser.image(data.value.image)
                            : undefined,
                    avatarBackgroundColor: colors.secondary.tints[8].hex
                }
            };
        case 'carouselCanvas':
            return {
                carousel: {
                    id: data.id,
                    slides: data.value.image.map((slide, index) => ({
                        id: `${data.id}-${index}`,
                        element: (
                            <NextImage
                                width={1320}
                                height={742.5}
                                style={{ maxWidth: '100%', height: 'auto' }}
                                {...cmsParser.image(slide)}
                            />
                        )
                    })),
                    slidesConfig: {
                        large: {
                            slidesToShow: 1,
                            gap: 0
                        },
                        medium: {
                            slidesToShow: 1.2,
                            gap: 1.5
                        },
                        small: {
                            slidesToShow: 1,
                            gap: 0
                        }
                    }
                }
            };
        default:
            return {};
    }
};

export default GraduateStoryPage;
